const portraitsData = [
    {
        category: 'portraits',
        id: '8',
        alt: '',
        title: '',
        blurhash: 'C18;Pu0000~qR+~pM{ae',
        orientation: 'portrait'
    },
    {
        category: 'portraits',
        id: '9',
        alt: '',
        title: '',
        blurhash: 'L39%*V~q00M{00M{-;of4nxu%Mof',
        orientation: 'landscape'
    },
    {
        category: 'portraits',
        id: '10',
        alt: '',
        title: '',
        blurhash: 'L15#R}LN0w~WS:O^-@-h00zm}858',
        orientation: 'landscape'
    },
    {
        category: 'portraits',
        id: '11',
        alt: '',
        title: '',
        blurhash: 'LWJHzC%Mxuj[~pt7fRoe4.M{M{t7',
        orientation: 'landscape'
    },
    {
        category: 'portraits',
        id: '1',
        alt: '',
        title: '',
        blurhash: 'LPBy?X~Wt7NG4:9GIVofxtxuxus:',
        orientation: 'landscape'
    },
    {
        category: 'portraits',
        id: '2',
        alt: '',
        title: '',
        blurhash: 'L69%*V~q00004n%M~q9FIUD%Rj?b',
        orientation: 'portrait'
    },
    {
        category: 'portraits',
        id: '3',
        alt: '',
        title: '',
        blurhash: 'L24.9:~q0000~q-;IUD%4nIU%M-;',
        orientation: 'portrait'
    },
    {
        category: 'portraits',
        id: '4',
        alt: '',
        title: '',
        blurhash: 'L69QN,~W0101-oxuOYE1009Z={^+',
        orientation: 'portrait'
    },
    {
        category: 'portraits',
        id: '5',
        alt: '',
        title: '',
        blurhash: 'LADvfyWBM{xu~qofWBayIUofofWB',
        orientation: 'landscape'
    },
    {
        category: 'portraits',
        id: '6',
        alt: '',
        title: '',
        blurhash: 'LxIgch~B-oIn$+o3NGNGMxV@aet7',
        orientation: 'landscape'
    },
    {
        category: 'portraits',
        id: '7',
        alt: '',
        title: '',
        blurhash: 'L56@m3tlKiMK~pn$t7IBBmic#l9[',
        orientation: 'portrait'
    },
];

export default portraitsData;
