const Wip = () => {
  return (
    <div className="pt-32 pb-16 flex justify-center items-center text-center text-xl lg:text-3xl">
      <p>
      Nothing to show you right now <br /> check back later, there might be something new to see here.
      </p>
    </div>
  );
};

export default Wip;
